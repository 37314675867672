import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const routes = require('../../routes');

const Dropdown = ({ book, class_obj }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { books } = class_obj;

  return (
    <div style={{ cursor: 'pointer' }}>
      <div
        className="
            flex
            rounded-lg
            overflow-hidden
            border border-gray-300
            shadow-lg
            mt-4
          "
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <img className="w-12 p-2" src={book.photo_url} alt="book1" />
        <div className="p-2">
          <div className="font-bold">{book.name}</div>
          <div>
            {book.content_count}
            {' '}
            papers
          </div>
        </div>
        <div className="ml-auto p-2">
          <StaticImage
            src="../../images/v-down.png"
            alt="hw-logo"
            placeholder="blurred"
            layout="fixed"
            width={18}
            height={10}
            className="my-4"
          />
        </div>
      </div>

      {isOpen ? (
        <div className="rounded-lg shadow-lg border border-gray-300 mt-1">
          {books.map((book_node) => (
            <Link
              to={`/${routes.book}/${class_obj.class_name}/${class_obj.subject}/${book_node.name}-${book_node.book_id}`}
            >
              <div
                className={
                  book.book_id === book_node.book_id
                    ? 'bg-secondary flex overflow-hidden shadow-lg'
                    : 'flex overflow-hidden shadow-lg'
                }
                key={book_node.book_id}
              >
                <img
                  className="w-12 p-2"
                  src={book_node.photo_url}
                  alt="book1"
                />
                <div className="p-2">
                  <div className="font-bold">{book_node.name}</div>
                  <div>
                    {book_node.content_count}
                    {' '}
                    papers
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Dropdown;
