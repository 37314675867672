import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import Layout from '../../components/base/layout';
import Dropdown from './dropdown';
import Seo from '../../components/base/seo';
import logFirebaseEvent from '../../utils/firebase';
import CustomLink from '../../components/base/Link';

const routes = require('../../routes');

const downloadWithoutLoginLimit = Number(
  process.env.GATSBY_ALLOW_DOWNLOAD_WITHOUT_LOGIN || '0',
);
export const getCanDownload = () => {
  if (typeof window !== 'undefined') {
    const { downloadCount, canDownload } = window.localStorage;
    if (canDownload) return true;
    if (Number(downloadCount || 0) < downloadWithoutLoginLimit) return true;

    return false;
  }
  return false;
};

const BookDetails = ({ pageContext: { book }, pageContext: { class_obj } }) => {
  const [isOpen, setIsOpen] = useState(false);

  React.useEffect(() => {
    logFirebaseEvent('book_open', { id: book.book_id, value: book.name });
  }, []);

  return (
    <Layout>
      <Seo
        title={`${book.name} | ${class_obj.class_name} | ${class_obj.subject}`}
        keywords={[
          book.name,
          class_obj.class_name,
          class_obj.subject,
          ...JSON.parse(process.env.GATSBY_BASE_KEYWORDS),
        ]}
      />

      <main className="my-2 mx-4">
        <div>
          <Link to="/">
            <StaticImage
              src="../../images/v-left.png"
              alt="hw-logo"
              placeholder="blurred"
              layout="fixed"
              width={10}
              height={18}
              className="my-4"
            />
          </Link>

          <div className="font-bold text-lg">{`${class_obj.class_name} ${class_obj.subject}`}</div>
          <div className="font-bold text-2xl">Select paper to download PDF</div>

          <Dropdown book={book} class_obj={class_obj} />
        </div>

        <div>
          <div className="grid grid-cols-2 gap-4 my-4">
            {book.files.map((file) => (
              <CustomLink
                to={
                  getCanDownload()
                    ? `/${routes.download}/${file.paper_id}`
                    : `/${routes.blocker}/${file.paper_id}`
                }
                key={file.paper_id}
                firebaseEvent={{
                  eventName: 'paper_click',
                  eventParams: { id: file.paper_id, value: file.name },
                }}
              >
                <div className="grid-col-1 bg-white rounded-lg shadow-md overflow-hidden">
                  <div className="m-3">
                    <img
                      src={file.thumbnail_url}
                      alt="book1"
                      className="w-full h-52 object-cover rounded"
                    />
                  </div>
                  <div className="m-4 text-center">
                    <span className="font-semibold">{file.name}</span>
                    <span className="block font-light text-sm">
                      {file.question_count}
                      {' '}
                      questions
                    </span>
                  </div>
                </div>
              </CustomLink>
            ))}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default BookDetails;
